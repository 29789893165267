* {
  padding:0px;
  margin:0px;
}

body {
  margin: 4rem;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  width: 100%;
  text-align: center;
}

.btn-section {
  display: flex;
  justify-content: center;
  text-align: center;
}

#title {
  display: flex;
  justify-content: center;
}

#logo {
  display: flex;
  height: 10rem;
  width: 10rem;
  margin-bottom: 3rem;
}

img {
  max-height: 100%;
  max-width: 100%;
}